<template>
  <b-row>
    <b-col cols="12">
      <b-card title="Oluştur">
        <b-card-text>
          <b-row>
            <b-col
              cols="6"
            >
              <b-form-group label="Departman">
                <v-select
                  v-model="formData.id_com_department"
                  :options="departments"
                  :reduce="department => department.id"
                  label="title"
                  placeholder="Seçiniz"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="6"
            >
              <b-form-group label="Marka">
                <v-select
                  v-model="formData.id_com_brand"
                  :options="brands"
                  :reduce="brand => brand.id"
                  label="title"
                  placeholder="Seçiniz"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
            >
              <b-form-group label="Platform">
                <v-select
                  v-model="formData.id_com_marketing_ad_platforms"
                  :options="platforms"
                  :reduce="platform => platform.id"
                  label="title"
                  placeholder="Seçiniz"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
            >
              <b-form-group label="Reklam Adı">
                <b-form-input
                  v-model="formData.title"
                  placeholder="Reklam Adı"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="6"
            >
              <b-form-group label="Başlangıç Tarihi">
                <b-form-datepicker
                  v-model="formData.sdate"
                  v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
                  locale="tr"
                  start-weekday="1"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="6"
            >
              <b-form-group label="Bitiş Tarihi">
                <b-form-datepicker
                  v-model="formData.edate"
                  v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
                  locale="tr"
                  start-weekday="1"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
            >
              <b-form-group label="Reklam Tipi">
                <v-select
                  v-model="formData.id_com_marketing_ad_types"
                  :options="types"
                  :reduce="type => type.id"
                  label="title"
                  placeholder="Seçiniz"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
            >
              <b-form-group label="Reklam Formu">
                <v-select
                  v-model="formData.id_com_marketing_ad_forms"
                  :options="forms"
                  :reduce="form => form.id"
                  label="title"
                  placeholder="Seçiniz"
                >
                  <template
                    slot="option"
                    slot-scope="option"
                  >
                    <div>{{ option.title }}</div>
                    <small>Reklam ID: {{ option.ads_id }}</small>
                  </template>
                  <template
                    slot="selected-option"
                    slot-scope="option"
                  >
                    {{ option.title }}
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col
              cols="4"
            >
              <b-form-group
                label="Erişim"
                label-for="access"
              >
                <cleave
                  id="access"
                  v-model="formData.access"
                  class="form-control"
                  :raw="true"
                  :options="options.number"
                  placeholder="Erişim"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="4"
            >
              <b-form-group
                label="Harcanan Tutar"
                label-for="spent"
              >
                <cleave
                  id="spent"
                  v-model="formData.spent"
                  class="form-control"
                  :raw="true"
                  :options="options.currency"
                  placeholder="Harcanan Tutar"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="4"
            >
              <b-form-group
                label="Toplam Bütçe"
                label-for="budget"
              >
                <cleave
                  id="budget"
                  v-model="formData.budget"
                  class="form-control"
                  :raw="true"
                  :options="options.currency"
                  placeholder="Toplam Bütçe"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
            >
              <b-form-group label="Yayın Durumu">
                <v-select
                  v-model="formData.status"
                  :options="statuses"
                  :reduce="status => status.id"
                  label="title"
                  placeholder="Seçiniz"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
            >
              <b-form-group label="Notlar">
                <b-form-textarea
                  v-model="formData.notes"
                  placeholder="Notlar"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-text>
      </b-card>
      <b-card title="Reklam Görselleri">
        <b-card-text>
          <b-form-group label="Dosya Yükle">
            <b-form-file
              v-model="formData.media"
              placeholder="Bir dosya seçin veya buraya bırakın..."
              drop-placeholder="Dosyayı buraya bırakın..."
              browse-text="Görsel Seçin"
              multiple
            />
          </b-form-group>
        </b-card-text>
      </b-card>
      <b-button
        variant="primary"
        @click="submitForm"
      >
        Kaydet
      </b-button>
    </b-col>
  </b-row>
</template>
<script>
import {
  BCard, BCardText, BRow, BCol, BFormInput, BButton, BFormGroup, BFormDatepicker, BFormTextarea, BFormFile,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'

export default {
  name: 'Add',
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormDatepicker,
    BFormTextarea,
    BFormInput,
    BFormFile,
    BButton,
    vSelect,
    Cleave,
  },
  data() {
    return {
      submitStatus: false,
      formData: {
        title: null,
        sdate: null,
        edate: null,
        access: null,
        spent: null,
        budget: null,
        status: null,
        notes: null,
        id_com_brand: null,
        id_com_department: null,
        id_com_marketing_ad_forms: null,
        id_com_marketing_ad_types: null,
        id_com_marketing_ad_platforms: null,
        media: [],
      },
      statuses: [
        {
          id: 1,
          title: 'Açık',
        },
        {
          id: 0,
          title: 'Kapalı',
        },
      ],
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalMark: ',',
          delimiter: '.',
        },
        currency: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalMark: ',',
          delimiter: '.',
        },
      },
    }
  },
  computed: {
    brands() {
      return this.$store.getters['brands/getBrands']
    },
    departments() {
      return this.$store.getters['departments/getDepartments']
    },
    types() {
      return this.$store.getters['marketingAdTypes/getMarketing_ad_types']
    },
    forms() {
      return this.$store.getters['marketingAdForms/getMarketing_ad_forms']
    },
    platforms() {
      return this.$store.getters['marketingAdPlatforms/getMarketing_ad_platforms']
    },
    saveStatus() {
      return this.$store.getters['marketingAds/getMarketing_adSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        this.formData = {
          id_com_brand: null,
          id_com_location: null,
          sdate: null,
          edate: null,
          count: null,
        }
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {
    this.getBrands()
    this.getAdTypes()
    this.getAdForms()
    this.getAdPlatforms()
    this.getDepartments()
  },
  methods: {
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    getBrands() {
      this.$store.dispatch('brands/brandsList', {
        select: ['com_brand.id AS id', 'com_brand.name AS title'],
      })
    },
    getAdTypes() {
      this.$store.dispatch('marketingAdTypes/marketing_ad_typesList', {
        select: ['com_marketing_ad_types.id AS id', 'com_marketing_ad_types.title AS title'],
      })
    },
    getAdForms() {
      this.$store.dispatch('marketingAdForms/marketing_ad_formsList', {
        select: [
          'com_marketing_ad_forms.id AS id',
          'com_marketing_ad_forms.title AS title',
          'com_marketing_ad_forms.ads_id AS ads_id',
        ],
        unique: true,
      })
    },
    getAdPlatforms() {
      this.$store.dispatch('marketingAdPlatforms/marketing_ad_platformsList', {
        select: ['com_marketing_ad_platforms.id AS id', 'com_marketing_ad_platforms.title AS title'],
      })
    },
    getDepartments() {
      this.$store.dispatch('departments/departmentsList', {
        select: ['com_department.id AS id', 'com_department.title AS title'],
        where: {
          'com_department.type': 1,
        },
      })
    },
    submitForm() {
      this.submitStatus = true
      this.$store.dispatch('marketingAds/marketing_adSave', this.formData)
    },
  },
}
</script>
